<template>
  <VDialog class="gap-4">
    <div class="p-3 flex items-center flex-col mt-2">
      <ASVG name="DownloadDialog" />
      <VHeading level="4" medium>
        {{ localizeText('shared.download_dialog.title') }}
      </VHeading>
      <h5 class="text-m font-medium mb-1 mx-2">
        {{ localizeText('shared.download_dialog.description') }}
      </h5>
      <p class="text-sm text-gray-500 dark:text-gray-400">
        {{ localizeText('shared.download_dialog.sub_text') }}
      </p>
    </div>
    <DocumentList
        :documents="exportPdfDocuments"
        @selected-documents-change="handleSelectedDocumentsChange"
    />
    <div class="flex justify-end gap-2 mt-4">
      <VButton @click="close">
        {{ localizeText('general.cancel') }}
      </VButton>
      <VButton @click="download" color="red">
        {{ localizeText('general.confirm') }}
      </VButton>
    </div>
  </VDialog>
</template>
<script setup lang="ts">
import DocumentList from './DocumentList.vue'
import ExportApi from '@api/Documents/ExportApi';
import {ref} from "vue";
import VDialog from "@component-library/dialogs/VDialog.vue";
import VButton from "@component-library/buttons/VButton.vue";
import ASVG from "~/features/_abstract/ASVG.vue";
import VHeading from '@component-library/labels/VHeading.vue';

const localizeText = window.localizeText

const exportPdfDocuments = Object.entries(AvvStore.state.exportableDocuments)
    .reduce((acc: Record<string, { name: string, formats: string[] }>, [id, doc]) => {
      if (doc.formats.length === 1 && doc.formats[0] === 'document_pdf') {
        acc[id] = { name: doc.name, formats: doc.formats };
      }
      return acc;
    }, {});

const exportDocxDocumentsIds = Object.entries(AvvStore.state.exportableDocuments)
    .filter(([id, doc]) => doc.formats.includes('document_docx'))
    .map(([id, doc]) => String(id));

const emit = defineEmits<{
  (e: 'close'): void
}>()

const close = () => {
  emit('close')
}

const selectedPdfExportsIds = ref<string[]>([]);

const handleSelectedDocumentsChange = (selectedDocumentIds: string[]) => {
  selectedPdfExportsIds.value = selectedDocumentIds;
};

const download = async () => {
  const allDocumentsIds = [...exportDocxDocumentsIds, ...selectedPdfExportsIds.value]

  await window.avv_download(
      ExportApi.create.path(),
      {
        document_ids: allDocumentsIds,
        formats: "docxorpdf"
      }
  )
  close()
}
</script>