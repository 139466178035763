<template>
  <div class="modern-color-theme font-poppins fixed inset-0 flex items-center justify-center" :class="computedClass">
    <slot />
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue';

defineOptions({
  name: 'VBackdrop'
})

const props = withDefaults(
  defineProps<{
    opaque?: boolean
  }>(),
  {
    opaque: false
  }
)

const computedClass = computed(() => ({
  // TODO: Clarify later, for now used exclusively in page loader which is ran on page with neutral/100 background
  'bg-neutral-100': props.opaque,
  'backdrop-blur-sm bg-white/30': !props.opaque
}))
</script>